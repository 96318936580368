.tooltip-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.tooltip-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #f0f0f0;
  color: #666;
  font-size: 12px;
  cursor: help;
  margin-right: 4px;
  border: 1px solid #ddd;
}

.tooltip-content {
  visibility: hidden;
  position: absolute;
  padding: 12px 16px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  min-width: 200px;
  max-width: 400px;
  width: max-content;
  font-size: 14px;
  line-height: 1.6;
  color: #333;
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;
  pointer-events: none;
  white-space: normal;
  word-wrap: break-word;
}

/* 垂直位置样式 */
.tooltip-bottom .tooltip-content {
  bottom: calc(100% + 8px);
}

.tooltip-top .tooltip-content {
  top: calc(100% + 8px);
}

/* 水平位置样式 */
.tooltip-center .tooltip-content {
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-left .tooltip-content {
  left: 0;
  transform: translateX(0);
}

.tooltip-right .tooltip-content {
  right: 0;
  transform: translateX(0);
}

/* 箭头样式 */
.tooltip-content::after {
  content: '';
  position: absolute;
  border-width: 8px;
  border-style: solid;
  z-index: 9999;
}

/* 底部显示时的箭头 */
.tooltip-bottom .tooltip-content::after {
  top: 100%;
  border-color: #fff transparent transparent transparent;
}

/* 顶部显示时的箭头 */
.tooltip-top .tooltip-content::after {
  bottom: 100%;
  border-color: transparent transparent #fff transparent;
}

/* 箭头水平位置 */
.tooltip-center .tooltip-content::after {
  left: 50%;
  margin-left: -8px;
}

.tooltip-left .tooltip-content::after {
  left: 20px;
}

.tooltip-right .tooltip-content::after {
  right: 20px;
}

.tooltip-container:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

/* 为最后一列的 tooltip 添加特殊处理 */
th:last-child .tooltip-content,
td:last-child .tooltip-content {
  left: auto;
  right: 0;
  transform: translateX(0);
}

/* 相应调整最后一列 tooltip 的箭头位置 */
th:last-child .tooltip-content::after,
td:last-child .tooltip-content::after {
  left: auto;
  right: 20px;
  margin-left: 0;
} 