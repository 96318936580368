.app-header {
  padding: 0.5rem 2rem; /* 减小上下内边距 */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #333333; /* 深灰色背景 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.left-section, .right-section {
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
  margin-right: 2rem;
  transition: color 0.3s ease;
}

.logo i {
  margin-right: 8px;
  font-size: 24px;
}

.logo:hover {
  color: #B388FF;
}

.nav-links {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  align-items: center;
  height: 100%;
}

.nav-links li {
  margin-right: 1.5rem;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-links a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 500;
  transition: color 0.3s ease;
  padding: 0.5rem 1rem;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-links a:hover {
  color: #B388FF;
  background-color: rgba(255, 255, 255, 0.1); /* 添加背景色变化 */
}

.language-selector {
  position: relative;
  margin-right: 1rem;
}

.language-selector select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #ffffff;
  border-radius: 4px;
  padding: 0.5rem 2rem 0.5rem 1rem;
  color: #ffffff;
  font-weight: 500;
  cursor: pointer;
  width: 120px; /* 增加宽度 */
}

.language-selector::after {
  content: '\25BC';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #ffffff;
  pointer-events: none;
}

.language-selector select:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}

/* 调整下拉菜单样式 */
.language-selector select option {
  background-color: #4a90e2;
  color: #ffffff;
  padding: 0.5rem;
}

.language-selector select,
.login-btn,
.logout-btn {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
}

.language-selector select:hover,
.login-btn:hover,
.logout-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.5);
}

.user-profile-link {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  margin-right: 1rem;
  transition: all 0.3s ease;
}

.user-profile-link:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.language-select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: pointer;
}

.language-select:hover {
  border-color: #4a90e2;
}

.language-select:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

/* 下拉菜单容器 */
.dropdown {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

/* 添加一个隐形的安全区域连接触发器和菜单 */
.dropdown::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 20px; /* 在触发器和菜单之间创建安全区域 */
  background: transparent;
}

/* 下拉触发器样式 */
.dropdown-trigger {
  cursor: pointer;
  padding: 0.5rem 1rem;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 100%;
  transition: all 0.3s ease;
  font-size: 15px; /* 与导航栏其他项目保持一致 */
}

.dropdown-trigger:hover {
  color: #B388FF; /* 与其他导航项目悬停颜色保持一致 */
}

/* 下拉菜单样式 */
.dropdown-menu {
  display: none;
  position: absolute;
  top: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #333333; /* 与导航栏背景色保持一致 */
  min-width: 280px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.3);
  border-radius: 8px;
  z-index: 1000;
  padding: 8px 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  white-space: nowrap;
}

/* 下拉菜单项样式 */
.dropdown-menu li a {
  color: #ffffff;
  padding: 12px 24px;
  text-decoration: none;
  display: block;
  transition: all 0.2s ease;
  font-weight: 400;
  font-size: 14px; /* 稍微小一点的字体大小 */
  white-space: nowrap;
  letter-spacing: 0.3px; /* 增加字母间距提高可读性 */
}

.dropdown-menu li a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #B388FF;
  transform: translateX(4px);
}

/* 小箭头样式 */
.dropdown-menu::before {
  content: '';
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: #333333; /* 与菜单背景色保持一致 */
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

/* 显示下拉菜单的条件也包括悬停在安全区域上 */
.dropdown:hover .dropdown-menu,
.dropdown-menu:hover {
  display: block;
  animation: fadeIn 0.2s ease;
}

/* 添加淡入动画 */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

/* 调整触发器箭头样式 */
.dropdown-trigger .arrow {
  margin-left: 6px;
  font-size: 10px;
  transition: transform 0.2s ease;
}

.dropdown:hover .dropdown-trigger .arrow {
  transform: rotate(180deg);
}
