.earnings-calendar-container {
  padding: 20px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  background: #ffffff;
}

.earnings-calendar-title {
  font-size: 28px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
  background: linear-gradient(90deg, #1a1a1a, #333);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.earnings-calendar-table-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.earnings-calendar-table-wrapper {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 1px;
}

.earnings-calendar-table {
  width: 100%;
  min-width: 1000px;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
}

.earnings-calendar-table th {
  position: sticky;
  top: 0;
  background: linear-gradient(145deg, #f8f9fa 0%, #ffffff 100%);
  font-weight: 600;
  color: #1a1a1a;
  padding: 16px;
  text-align: left;
  white-space: nowrap;
  border-bottom: 2px solid #e0e0e0;
  z-index: 1;
  transition: background-color 0.2s ease;
}

.earnings-calendar-table td {
  padding: 12px 16px;
  border-bottom: 1px solid #f0f0f0;
  white-space: nowrap;
}

.earnings-calendar-table tr:hover {
  background: linear-gradient(90deg, rgba(248, 249, 250, 0.5) 0%, rgba(255, 255, 255, 0.8) 100%);
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.earnings-calendar-ticker-link {
  color: #1a73e8;
  cursor: pointer;
  font-weight: 500;
  transition: color 0.2s ease;
}

.earnings-calendar-ticker-link:hover {
  color: #174ea6;
  text-decoration: underline;
}

.earnings-calendar-th-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.earnings-calendar-th-subtitle {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: normal;
}

.earnings-calendar-comparison {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
}

.earnings-calendar-separator {
  color: #dadce0;
  font-weight: normal;
}

.earnings-calendar-reported {
  color: #34a853;
  font-weight: 600;
}

.earnings-calendar-prime {
  color: #1a73e8;
  font-weight: 500;
}

.earnings-calendar-consensus {
  color: #5f6368;
}

.earnings-calendar-loading,
.earnings-calendar-error {
  padding: 40px;
  text-align: center;
  font-size: 16px;
  color: #5f6368;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.earnings-calendar-error {
  color: #dc3545;
}

/* 精确控制每列宽度 */
.earnings-calendar-table th:nth-child(1),
.earnings-calendar-table td:nth-child(1) {
  width: 100px;
  padding-left: 24px;
}

.earnings-calendar-table th:nth-child(2),
.earnings-calendar-table td:nth-child(2),
.earnings-calendar-table th:nth-child(3),
.earnings-calendar-table td:nth-child(3) {
  width: 120px;
}

.earnings-calendar-table th:nth-child(4),
.earnings-calendar-table td:nth-child(4),
.earnings-calendar-table th:nth-child(5),
.earnings-calendar-table td:nth-child(5) {
  width: 260px;
  padding-right: 24px;
}

/* Scrollbar styles */
.earnings-calendar-table-wrapper::-webkit-scrollbar {
  height: 8px;
}

.earnings-calendar-table-wrapper::-webkit-scrollbar-track {
  background: #f1f3f4;
  border-radius: 4px;
}

.earnings-calendar-table-wrapper::-webkit-scrollbar-thumb {
  background: #dadce0;
  border-radius: 4px;
}

.earnings-calendar-table-wrapper::-webkit-scrollbar-thumb:hover {
  background: #bdc1c6;
}

/* 响应式设计 */
@media screen and (max-width: 1200px) {
  .earnings-calendar-container {
    padding: 16px;
  }
  
  .earnings-calendar-table-wrapper {
    margin: 0 -16px;
    padding: 0 16px;
    width: calc(100% + 32px);
  }
}

/* 添加响应式设计 */
@media screen and (max-width: 768px) {
  .earnings-calendar-container {
    padding: 12px;
  }

  .earnings-calendar-title {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .earnings-calendar-table td,
  .earnings-calendar-table th {
    padding: 12px;
  }
}

/* Add new styles for filters */
.earnings-calendar-header {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.earnings-calendar-title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 日期范围选择器容器 */
.earnings-calendar-date-range-container {
  width: 100%;
  margin: 16px 0;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

/* 修复日期选择器容器定位和样式 */
.date-picker-container {
  position: relative;
  margin-left: auto;
}

/* 修复日期选择器下拉面板样式 */
.date-picker-dropdown {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  width: 280px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 20px;
  z-index: 1000;
}

/* 修复自定义日期范围按钮样式 */
.custom-date-range-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #dadce0;
  border-radius: 6px;
  font-size: 14px;
  color: #1a73e8;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
}

.custom-date-range-btn:hover {
  background: #f8f9fa;
  border-color: #1a73e8;
}

.custom-date-range-btn svg {
  color: #5f6368;
}

/* 修复日期选择器输入框样式 */
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #dadce0;
  border-radius: 8px;
  font-size: 14px;
  color: #202124;
  background: #ffffff;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.react-datepicker__input-container input:hover {
  border-color: #bdc1c6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.react-datepicker__input-container input:focus {
  outline: none;
  border-color: #1a73e8;
  box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.1);
}

.react-datepicker__input-container input::placeholder {
  color: #80868b;
}

/* 修复月份导航样式 */
.month-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
  padding: 8px 0;
  border-top: 1px solid #dadce0;
  border-bottom: 1px solid #dadce0;
}

.month-title {
  font-size: 14px;
  color: #202124;
  font-weight: 500;
}

.month-nav-buttons {
  display: flex;
  gap: 4px;
}

.month-nav-btn {
  padding: 4px 8px;
  background: none;
  border: 1px solid transparent;
  color: #1a73e8;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.month-nav-btn:hover {
  background: #f1f3f4;
  border-color: #dadce0;
}

/* 修复操作按钮样式 */
.date-picker-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px solid #f1f3f4;
}

.apply-btn {
  padding: 10px 24px;
  background: #1a73e8;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(26, 115, 232, 0.2);
}

.apply-btn:hover {
  background: #1557b0;
  box-shadow: 0 2px 6px rgba(26, 115, 232, 0.3);
  transform: translateY(-1px);
}

.apply-btn:disabled {
  background: #dadce0;
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
}

/* 移除重复的样式定义 */
.earnings-calendar-date-picker-dropdown,
.earnings-calendar-date-picker-actions,
.earnings-calendar-apply-btn,
.earnings-calendar-clear-btn {
  display: none;
}

/* 当前筛选显示 */
.earnings-calendar-current-filter {
  font-size: 18px;
  font-weight: 500;
  color: #1a73e8;
  padding: 8px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background: #f8f9fa;
  display: inline-block;
  margin-bottom: 16px;
}

.earnings-calendar-date-range {
  display: flex;
  align-items: center;
  gap: 8px;
}

.earnings-calendar-date-range::before {
  content: '📅';
  font-size: 16px;
}

/* 确保日期选择器不会溢出 */
.earnings-calendar-date-filter {
  position: relative;
}

/* 新增和修改的样式 */
.earnings-calendar-filters-container {
  margin-bottom: 24px;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 8px;
}

.earnings-calendar-quick-filters {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}

.earnings-calendar-quick-filter-btn {
  padding: 10px 20px;
  background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
  border: 1px solid #dadce0;
  border-radius: 8px;
  font-size: 14px;
  color: #5f6368;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.earnings-calendar-quick-filter-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-color: #1a73e8;
}

.earnings-calendar-quick-filter-btn.active {
  background: linear-gradient(135deg, #e8f0fe 0%, #d2e3fc 100%);
  color: #1a73e8;
  border-color: #1a73e8;
  box-shadow: 0 4px 12px rgba(26, 115, 232, 0.15);
}

.earnings-calendar-date-filter {
  position: relative;
  margin-left: auto;
}

.earnings-calendar-date-picker-btn {
  padding: 8px 16px;
  background: #f8f9fa;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 15px;
  color: #1a73e8;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.earnings-calendar-date-picker-btn:hover {
  background: #f0f7ff;
  border-color: #1a73e8;
}

.earnings-calendar-date-picker-dropdown {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: 16px;
  width: 280px;
  z-index: 1000;
  border: 1px solid #e0e0e0;
}

.date-input-group {
  margin-bottom: 16px;
  width: 100%;
}

.date-input-group label {
  display: block;
  font-size: 13px;
  color: #5f6368;
  margin-bottom: 6px;
  font-weight: 500;
}

/* 美化操作按钮 */
.earnings-calendar-date-picker-actions {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.earnings-calendar-apply-btn {
  flex: 1;
  padding: 8px 16px;
  background: #1a73e8;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.earnings-calendar-apply-btn:hover {
  background: #1557b0;
}

.earnings-calendar-clear-btn {
  flex: 1;
  padding: 8px 16px;
  background: #f8f9fa;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 14px;
  color: #5f6368;
  cursor: pointer;
  transition: all 0.2s ease;
}

.earnings-calendar-clear-btn:hover {
  background: #f1f3f4;
  border-color: #dadce0;
}

/* 确保日期选择器不会溢出 */
.react-datepicker-wrapper {
  width: 100%;
  margin: 0;
}

.react-datepicker-popper {
  z-index: 1001 !important;
}

/* 添加新的样式 */
.earnings-calendar-no-data {
  text-align: center;
  padding: 40px !important;
  color: #5f6368;
  font-size: 14px;
  background: #f8f9fa;
}

/* 日期标题行样式 */
.earnings-calendar-date-header td {
  background: #f1f3f4;
  font-weight: 600;
  padding: 16px 24px !important;
  font-size: 16px;
  border-top: 1px solid #e8eaed;
  letter-spacing: 0.3px;
  position: relative;
  z-index: 2;
  text-align: left;
}

/* 日期文本容器 */
.earnings-calendar-date-text {
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
  color: #3c4043;
  vertical-align: middle;
  padding-left: 24px;
}

/* 确保日期选择器容器不会溢出 */
.earnings-calendar-date-inputs {
  width: 100%;
  box-sizing: border-box;
}

/* 日期选择器容器 */
.date-picker-container {
  position: relative;
  margin-left: auto;
}

/* 自定义日期范围按钮 */
.custom-date-range-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #dadce0;
  border-radius: 6px;
  font-size: 14px;
  color: #1a73e8;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
}

.custom-date-range-btn:hover {
  background: #f8f9fa;
  border-color: #1a73e8;
}

.custom-date-range-btn svg {
  color: #5f6368;
}

/* 日期输入区域 */
.date-range-inputs {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
}

.date-input-group {
  width: 100%;
  box-sizing: border-box;
}

/* 修复输入框宽度问题 */
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  width: 100%;
  box-sizing: border-box;
}

.react-datepicker__input-container input {
  padding: 10px 12px;
  border: 1px solid #dadce0;
  border-radius: 8px;
  font-size: 14px;
  color: #202124;
  background: #ffffff;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.react-datepicker__input-container input:hover {
  border-color: #bdc1c6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.react-datepicker__input-container input:focus {
  outline: none;
  border-color: #1a73e8;
  box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.1);
}

.react-datepicker__input-container input::placeholder {
  color: #80868b;
}

/* 操作按钮 */
.date-picker-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px solid #f1f3f4;
}

/* 日期选择器弹出层样式 */
.react-datepicker {
  font-family: inherit;
  border-color: #dadce0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.react-datepicker__header {
  background: #f8f9fa;
  border-bottom: 1px solid #dadce0;
}

.react-datepicker__day {
  color: #202124;
}

.react-datepicker__day:hover {
  background: #f1f3f4;
}

.react-datepicker__day--selected {
  background: #1a73e8;
  color: white;
}

.react-datepicker__day--keyboard-selected {
  background: #e8f0fe;
  color: #1a73e8;
}

.react-datepicker__day--disabled {
  color: #dadce0;
}

/* 确保日期选择器不会被其他元素遮挡 */
.react-datepicker-popper {
  z-index: 1001 !important;
}

/* 标题样式 */
.date-picker-title {
  font-size: 16px;
  font-weight: 600;
  color: #202124;
  margin-bottom: 20px;
} 