.longterm-container {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.longterm-sidebar {
  min-width: 260px;
  width: 260px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #e0e0e0;
  background: #fff;
  padding-right: 0;
}

.longterm-main-content {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  position: relative;
  max-width: calc(100% - 260px);
}

/* 复用 ProductsPage.css 中的侧边栏样式 */
.longterm-sidebar-nav {
  padding: 0;
}

.longterm-nav-item-container {
  margin-bottom: 4px;
}

.longterm-sidebar-item {
  width: 100%;
  text-align: left;
  padding: 12px 20px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 15px;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  letter-spacing: 0.3px;
  position: relative;
}

.longterm-sidebar-item.active {
  background-color: #f0f7ff;
  color: #1a73e8;
  font-weight: 500;
}

.longterm-sidebar-item:hover {
  background-color: #f5f5f5;
  color: #1a73e8;
}

.longterm-sidebar-item.group {
  font-weight: 600;
  font-size: 16px;
  color: #333333;
  background-color: transparent;
}

.longterm-sub-items {
  position: relative;
  margin-left: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #fafafa;
}

.longterm-feature-tag {
  font-size: 11px;
  padding: 1px 6px;
  border-radius: 3px;
  font-weight: 500;
  display: inline-block;
}

.longterm-free-tag {
  background-color: #E8F5E9;
  color: #2E7D32;
}

.longterm-expand-icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f0f0f0;
  transition: all 0.3s ease;
  font-size: 12px;
  color: #666666;
}

.longterm-sidebar-item:hover .longterm-expand-icon {
  background-color: #e0e0e0;
}

.longterm-sidebar-item[aria-expanded="true"] .longterm-expand-icon {
  transform: rotate(180deg);
  background-color: #1a73e8;
  color: white;
}

.longterm-stock-list {
  position: relative;
  margin-left: 20px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #fafafa;
}

.longterm-stock-item {
  padding-left: 40px !important;
  font-size: 14px !important;
}

/* Add transition for sub-items */
.longterm-sub-items {
  position: relative;
  margin-left: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #fafafa;
} 