html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.main-landing-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: transparent;
}

.hero-section {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  overflow: hidden;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  z-index: 1;
}

.hero-content {
  padding-bottom: 15vh;
  z-index: 2;
  text-align: center;
  animation: fadeIn 1.2s ease-out;
}

.animated-title {
  position: relative;
  display: inline-block;
  font-size: 7em;
  color: #ffffff;
  margin-bottom: 0.3em;
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.4);
  font-weight: 800;
  letter-spacing: -0.02em;
  z-index: 2;
}

.letter {
  display: inline-block;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  opacity: 0.95;
  position: relative;
}

.letter.active {
  transform: translateY(-25px) scale(1.1);
  color: #4a90e2;
  text-shadow: 0 0 40px rgba(74, 144, 226, 0.8),
               0 0 80px rgba(74, 144, 226, 0.4);
  opacity: 1;
}

.jumping-star {
  position: absolute;
  font-size: 0.6em;
  filter: drop-shadow(0 0 20px rgba(255, 215, 0, 0.9));
  animation: starPulse 2s ease-in-out infinite;
}

@keyframes starPulse {
  0%, 100% { 
    transform: translateY(-30px) scale(1) rotate(0deg);
    filter: drop-shadow(0 0 20px rgba(255, 215, 0, 0.9));
  }
  50% { 
    transform: translateY(-45px) scale(1.5) rotate(180deg);
    filter: drop-shadow(0 0 30px rgba(255, 215, 0, 1));
  }
}

.slogan {
  font-size: 1.8em;
  color: rgba(255, 255, 255, 0.95);
  margin-bottom: 2em;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  z-index: 2;
  line-height: 1.4;
}

.cta-button {
  font-size: 1.4em;
  padding: 1.2em 3em;
  background: linear-gradient(135deg, #4a90e2 0%, #357abd 100%);
  color: white;
  border: none;
  border-radius: 50px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  box-shadow: 0 4px 20px rgba(74, 144, 226, 0.4),
              0 0 40px rgba(74, 144, 226, 0.2);
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, 
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  transition: 0.6s;
}

.cta-button:hover {
  transform: translateY(-4px) scale(1.02);
  box-shadow: 0 8px 25px rgba(74, 144, 226, 0.6),
              0 0 50px rgba(74, 144, 226, 0.4);
  background: linear-gradient(135deg, #357abd 0%, #4a90e2 100%);
}

.cta-button:hover::before {
  left: 100%;
}

.content-wrapper {
  margin-top: 0;
  padding-top: 100px;
  position: relative;
  z-index: 4;
  background-color: transparent !important;
}

.interactive-panels {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  max-width: 1800px;
  margin: 0 auto;
}

.panel {
  flex: 1 1 450px;
  max-width: 550px;
  min-width: 450px;
  background: #fff;
  border-radius: 16px;
  padding: 2.5rem 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.panel-title {
  font-size: 1.8em;
  margin-bottom: 1rem;
  color: #333;
}

.section {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-title {
  font-size: 1.2em;
  margin-bottom: 0.75rem;
  font-weight: 600;
  color: #333;
}

.section-description {
  font-size: 0.9em;
  color: #666;
  margin: 0;
  line-height: 1.6;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 768px) {
  .interactive-panels {
    grid-template-columns: 1fr;
  }
}

.jumping-star {
  position: absolute;
  font-size: 0.8em;
  transition: all 0.3s ease;
  animation: starJump 0.6s ease infinite;
}

@keyframes letterJump {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}

@keyframes starJump {
  0%, 100% { transform: translateY(-20px) scale(1); }
  50% { transform: translateY(-30px) scale(1.2); }
}

.hero-section .slogan {
  font-size: 1.5em;
  margin-bottom: 30px;
  color: #ffffff;
  font-weight: 500;
}

.cta-button {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: #ffffff;
  color: #4a90e2;
  text-decoration: none;
  border-radius: 30px;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  background-color: #4a90e2;
  color: #ffffff;
}

.content-wrapper {
  position: relative;
  z-index: 2;
  background-color: transparent !important;
  padding-top: 20px;
}

.interactive-panels {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.panel {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 2rem;
  transition: all 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  color: white;
  text-align: center;
}

.panel:hover {
  transform: translateY(-30px);
  box-shadow: 0 20px 40px rgba(1, 111, 236, 0.5);
}

.panel-icon {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.panel-title {
  font-size: 2.2em;
  margin-bottom: 1.5rem;
  font-weight: 700;
  color: #ffffff;
}

.panel-subtitle {
  font-size: 1.2em;
  margin-bottom: 1.5rem;
  font-weight: 400;
  color: #ffffff;
}

.panel-description {
  opacity: 1;
  max-height: none;
  overflow: visible;
}

.section {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 15px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  transition: all 0.3s ease;
}

.section-title {
  font-size: 1.4em;
  margin-bottom: 0.75rem;
  font-weight: 600;
  color: #333;
}

.free-section .section-title {
  font-size: 1.2em !important;
  margin-bottom: 0.75rem;
  font-weight: 600;
  color: #ffffff !important;
}

.free-section .section-description {
  font-size: 0.9em !important;
  color: rgba(255, 255, 255, 0.9) !important;
  margin: 0;
  line-height: 1.6;
}

.pro-section {
  background: rgba(255, 215, 0, 0.2) !important;
  box-shadow: 0 4px 10px rgba(255, 215, 0, 0.3);
  min-height: 180px;
}

.pro-section .section-title {
  font-size: 1.2em !important;
  margin-bottom: 0.75rem;
  font-weight: 600;
  color: #FFD700 !important;
}

.pro-section .section-description {
  font-size: 0.9em !important;
  color: #ffffff !important;
  margin: 0;
  line-height: 1.6;
}

.section:hover {
  transform: none;
  box-shadow: none;
}

.pro-section:hover {
  box-shadow: none;
}

.panel a, .panel a:hover, .panel a:visited {
  text-decoration: none;
  color: inherit;
}

.section, .panel {
  background: transparent !important;
  backdrop-filter: none;
}

.panel-section {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 15px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  transition: all 0.3s ease;
}

.panel-section-title {
  font-size: 1.2em;
  margin-bottom: 0.75rem;
  font-weight: 600;
  color: #ffffff;
}

.panel-section-description {
  font-size: 0.9em;
  color: rgba(255, 255, 255, 0.9);
  margin: 0;
  line-height: 1.6;
}

.free-panel-section-title {
  color: #ffffff;
}

.free-panel-section-description {
  color: rgba(255, 255, 255, 0.9);
}

.pro-panel-section {
  background: rgba(255, 215, 0, 0.3);
  box-shadow: 0 4px 10px rgba(255, 215, 0, 0.5);
}

.pro-panel-section-title {
  color: #FFD700;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.pro-panel-section-description {
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.panel-tag {
  position: absolute;
  font-size: 0.65em;
  padding: 0.15em 0.5em;
  top: 0.5rem;
  right: 0.5rem;
  border-radius: 4px;
}

.free-panel-tag {
  background-color: #4CAF50;
  color: white;
}

.pro-panel-tag {
  background-color: #FFC107;
  color: black;
}

.panel-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.pro-panel-section:hover {
  box-shadow: 0 6px 15px rgba(255, 215, 0, 0.5);
}

.panel {
  transition: all 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  color: #333;
  text-align: center;
  height: auto;
}

.panel:hover {
  transform: translateY(-30px);
  box-shadow: 0 20px 40px rgba(1, 111, 236, 0.5);
}

.panel-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.panel-title {
  font-size: 2.2em;
  margin-bottom: 1rem;
  font-weight: 700;
  color: #333;
}

.panel-description {
  opacity: 1;
  max-height: none;
  overflow: visible;
}

.panel:hover .panel-description {
  opacity: 1;
  max-height: none;
}

.content-wrapper {
  background-color: #FFFFFF;
  padding-top: 20px;
  position: relative;
  z-index: 2;
}

.interactive-panels {
  background-color: #FFFFFF;
  position: relative;
  z-index: 2;
}

.section {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
}

.section-title {
  font-size: 1.4em;
  margin-bottom: 0.75rem;
  font-weight: 600;
  color: #333;
}

.section-description {
  font-size: 0.9em;
  color: #333;
  margin: 0;
  line-height: 1.6;
}

.free-section .section-title {
  color: #333 !important;
}

.free-section .section-description {
  color: #333 !important;
}

.pro-section {
  background: rgba(255, 215, 0, 0.2) !important;
  box-shadow: 0 4px 10px rgba(255, 215, 0, 0.3);
}

.pro-section .section-title {
  color: #B8860B !important;
}

.pro-section .section-description {
  color: #333 !important;
}

.tag {
  position: absolute;
  font-size: 0.65em;
  padding: 0.15em 0.5em;
  top: 0.5rem;
  right: 0.5rem;
  border-radius: 4px;
}

.free-tag {
  background-color: #4CAF50;
  color: white;
}

.pro-tag {
  background-color: #FFC107;
  color: black;
}

.page-layout {
  background-color: transparent;
}

.main-content {
  background-color: transparent;
}

.main-page {
  padding-top: 40px;
}

@media (max-width: 768px) {
  .hero-section {
    padding-top: 60px;
  }

  .animated-title {
    font-size: 4em;
  }

  .interactive-panels {
    grid-template-columns: 1fr;
  }
}

.animated-title {
  position: relative;
  display: inline-block;
  font-size: 4em;
  color: white;
  margin-bottom: 0.5em;
}

.letter {
  display: inline-block;
  transition: transform 0.3s ease, color 0.3s ease;
}

.letter.active {
  transform: translateY(-10px);
  color: #FFD700;
}

.jumping-star {
  position: absolute;
  font-size: 0.5em;
  transition: all 0.3s ease;
  animation: starJump 0.6s ease infinite;
}

@keyframes starJump {
  0%, 100% { transform: translateY(-20px) scale(1); }
  50% { transform: translateY(-30px) scale(1.2); }
}

.hero-section {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  background-image: url('../images/0207.png');
  background-size: cover;
  background-position: center;
  z-index: 1;
  padding-bottom: 15vh;
}

.slogan {
  font-size: 1.2em;
  margin-bottom: 1em;
}

.cta-button {
  font-size: 1em;
  padding: 0.8em 1.6em;
}

.wave-divider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 400px;
  z-index: 5;
  overflow: hidden;
}

.wave-divider svg {
  width: 200%;
  height: 100%;
}

.wave1, .wave2, .wave3 {
  animation: wave 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform-origin: 50% 50%;
}

.wave2 {
  animation-delay: -2s;
  animation-duration: 12s;
}

.wave3 {
  animation-delay: -4s;
  animation-duration: 10s;
}

@keyframes wave {
  0% {
    transform: translateX(0) scaleY(1);
  }
  50% {
    transform: translateX(-25%) scaleY(0.8);
  }
  100% {
    transform: translateX(-50%) scaleY(1);
  }
}

.content-wrapper {
  margin-top: 0;
}

.panel {
  transition: all 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 2rem;
  position: relative;
  overflow: hidden;
}

.panel:hover {
  transform: translateY(-30px);
  box-shadow: 0 20px 40px rgba(1, 111, 236, 0.5);
}

.panel::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  background: linear-gradient(45deg, rgba(1, 111, 236, 0.2), rgba(1, 111, 236, 0));
  opacity: 0;
  transition: opacity 0.3s ease;
}

.panel:hover::after {
  opacity: 1;
}

.panel > * {
  position: relative;
  z-index: 1;
}

.section, .pro-section {
  transition: none;
  box-shadow: none;
}

.section:hover, .pro-section:hover {
  transform: none;
  box-shadow: none;
}

.panel.clickable {
  cursor: pointer;
}

.panel.clickable:hover {
  transform: translateY(-30px);
  box-shadow: 0 20px 40px rgba(1, 111, 236, 0.5);
}

.panel-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.coming-soon-tag {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #FF6B6B;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
  font-weight: 600;
  text-transform: uppercase;
}

.section-description {
  text-align: center;
  margin: 0.8em 0;
  line-height: 1.6;
  color: #666;
}

.pro-section .section-description {
  white-space: pre-line;
  margin-bottom: 1em;
}

.section-points {
  list-style: none;
  padding-left: 0;
  margin: 0.5rem 0;
  text-align: left;
}

.section-point {
  position: relative;
  padding-left: 1.5em;
  margin: 0.8em 0;
  font-size: 0.95em;
  color: #666;
  line-height: 1.4;
}

.pro-section {
  background-color: rgba(255, 215, 0, 0.1);
  border-radius: 12px;
  padding: 1.5em;
}

.pro-section .section-title {
  color: #FFB800;
  margin-bottom: 1em;
}

@media (max-width: 1200px) {
  .interactive-panels {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .interactive-panels {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1400px) {
  .interactive-panels {
    grid-template-columns: repeat(2, 1fr);
    max-width: 1200px;
  }
}

@media (max-width: 900px) {
  .interactive-panels {
    grid-template-columns: 1fr;
    max-width: 600px;
  }
  
  .panel {
    min-width: 280px;
  }
}

@media (max-width: 1500px) {
  .panel {
    flex: 1 1 400px;
    min-width: 400px;
  }
}

@media (max-width: 900px) {
  .panel {
    flex: 1 1 100%;
    min-width: 300px;
    max-width: 100%;
  }
}

.section {
  text-align: center;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.section-title {
  margin-bottom: 1rem;
  font-size: 1.4em;
}

.section-description {
  margin: 1rem 0;
  line-height: 1.6;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@media (max-width: 1200px) {
  .animated-title {
    font-size: 6em;
  }
  
  .slogan {
    font-size: 1.6em;
  }
  
  .cta-button {
    font-size: 1.3em;
    padding: 1em 2.5em;
  }
}

@media (max-width: 768px) {
  .animated-title {
    font-size: 4em;
  }
  
  .slogan {
    font-size: 1.4em;
  }
  
  .cta-button {
    font-size: 1.2em;
    padding: 0.9em 2em;
  }
}

/* 彩带容器 */
.ribbon-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2; /* 确保在背景之上 */
  overflow: hidden;
}

/* 彩带样式 */
.ribbon {
  position: absolute;
  width: 2px;
  height: 20px;
  border-radius: 1px;
  opacity: 0.6;
  animation: ribbon-float 8s ease-in-out infinite;
}

/* 创建多个彩带，使用不同的颜色和动画延迟 */
.ribbon:nth-child(1) {
  left: 10%;
  background: #4a90e2;
  animation-delay: 0s;
  animation-duration: 7s;
}

.ribbon:nth-child(2) {
  left: 20%;
  background: #FFD700;
  animation-delay: 1s;
  animation-duration: 6s;
}

.ribbon:nth-child(3) {
  left: 30%;
  background: #50E3C2;
  animation-delay: 2s;
  animation-duration: 8s;
}

.ribbon:nth-child(4) {
  left: 40%;
  background: #FF6B6B;
  animation-delay: 0.5s;
  animation-duration: 7.5s;
}

.ribbon:nth-child(5) {
  left: 50%;
  background: #4a90e2;
  animation-delay: 1.5s;
  animation-duration: 6.5s;
}

.ribbon:nth-child(6) {
  left: 60%;
  background: #FFD700;
  animation-delay: 2.5s;
  animation-duration: 7.8s;
}

.ribbon:nth-child(7) {
  left: 70%;
  background: #50E3C2;
  animation-delay: 0.8s;
  animation-duration: 6.8s;
}

.ribbon:nth-child(8) {
  left: 80%;
  background: #FF6B6B;
  animation-delay: 1.8s;
  animation-duration: 7.2s;
}

.ribbon:nth-child(9) {
  left: 90%;
  background: #4a90e2;
  animation-delay: 2.2s;
  animation-duration: 6.2s;
}

/* 彩带飘动动画 */
@keyframes ribbon-float {
  0% {
    transform: translateY(-100%) rotate(0deg) scaleY(1);
    opacity: 0;
  }
  10% {
    opacity: 0.6;
  }
  90% {
    opacity: 0.6;
  }
  100% {
    transform: translateY(100vh) rotate(360deg) scaleY(1.5);
    opacity: 0;
  }
}

/* 确保其他内容在彩带之上 */
.hero-content {
  position: relative;
  z-index: 3;
}

