.longterm-growth-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.longterm-growth-overview {
  padding: 32px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
}

.longterm-growth-content {
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  gap: 32px;
  align-items: start;
}

.longterm-growth-details {
  padding: 32px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
}

.longterm-growth-eps {
  padding: 32px;
  background: #ffffff;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.longterm-growth-eps-title {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 24px;
}

.longterm-growth-eps-grid {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.longterm-growth-eps-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.longterm-growth-eps-item:hover {
  background-color: #f8fafc;
}

.longterm-growth-eps-item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.longterm-growth-eps-label {
  font-size: 16px;
  color: #64748b;
  font-weight: 500;
}

.longterm-growth-eps-value {
  font-size: 18px;
  font-weight: 600;
  font-family: 'SF Mono', SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;
  letter-spacing: -0.5px;
}

.longterm-growth-eps-value.positive {
  color: #22c55e;
}

.longterm-growth-eps-value.negative {
  color: #ef4444;
}

.longterm-growth-title {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0 0 32px 0;
}

.longterm-growth-rating-section {
  margin: 32px 0;
  padding: 0 12px;
}

.longterm-growth-rating {
  max-width: 600px;
  margin: 0 auto;
}

.longterm-growth-rating-gauge {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.longterm-growth-rating-circle {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #f5f5f5;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.longterm-growth-rating-segments {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  transform: rotate(-90deg);
}

.longterm-growth-rating-segment {
  position: absolute;
  width: 50%;
  height: 50%;
  transform-origin: 100% 100%;
  transition: opacity 0.3s ease;
}

.longterm-growth-rating-segment.segment-E {
  transform: rotate(0deg) skew(30deg);
  background-color: #ef5350; /* Very Low - Dark Red */
}

.longterm-growth-rating-segment.segment-D {
  transform: rotate(72deg) skew(30deg);
  background-color: #ff7043; /* Low - Light Red */
}

.longterm-growth-rating-segment.segment-C {
  transform: rotate(144deg) skew(30deg);
  background-color: #ffa726; /* Medium - Orange */
}

.longterm-growth-rating-segment.segment-B {
  transform: rotate(216deg) skew(30deg);
  background-color: #66bb6a; /* High - Light Green */
}

.longterm-growth-rating-segment.segment-A {
  transform: rotate(288deg) skew(30deg);
  background-color: #43a047; /* Very High - Dark Green */
}

.longterm-growth-rating-needle {
  position: absolute;
  width: 4px;
  height: 50%;
  left: calc(50% - 2px);
  bottom: 50%;
  transform-origin: bottom center;
  transition: transform 0.3s ease;
  z-index: 2;
}

.longterm-growth-rating-center {
  position: absolute;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 3;
}

.longterm-growth-rating-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding: 0 16px;
}

.longterm-growth-rating-labels span {
  font-size: 14px;
  color: #666;
  transition: color 0.3s ease;
}

.longterm-growth-rating-labels span.active {
  color: #1a1a1a;
  font-weight: 600;
}

.longterm-growth-rating-value {
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  margin-top: 16px;
  transition: color 0.3s ease;
}

.longterm-growth-metrics {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.longterm-growth-metrics-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}

.longterm-growth-metric-card {
  background: #ffffff;
  border-radius: 16px;
  padding: 24px 28px;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.longterm-growth-metric-card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.03);
  transform: translateY(-1px);
  transition: all 0.2s ease;
}

.longterm-growth-metric-title {
  font-size: 20px;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.longterm-growth-metric-values {
  display: flex;
  flex-direction: column;
}

.longterm-growth-metric-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.longterm-growth-metric-row:last-child {
  border-bottom: none;
}

.longterm-growth-metric-period {
  font-size: 16px;
  color: #64748b;
  font-weight: 500;
}

.longterm-growth-metric-value {
  font-size: 18px;
  font-weight: 600;
  font-family: 'SF Mono', SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;
  letter-spacing: -0.5px;
}

.longterm-growth-metric-value.positive {
  color: #22c55e;
}

.longterm-growth-metric-value.negative {
  color: #ef4444;
}

@media (max-width: 1200px) {
  .longterm-growth-metrics-row {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .longterm-growth-metric-card {
    padding: 20px 24px;
  }
}

@media (max-width: 768px) {
  .longterm-growth-content {
    grid-template-columns: 1fr;
  }
  
  .longterm-growth-overview,
  .longterm-growth-details {
    padding: 24px;
  }

  .longterm-growth-eps {
    padding: 24px;
  }

  .longterm-growth-eps-title {
    font-size: 20px;
    margin-bottom: 24px;
  }

  .longterm-growth-eps-label {
    font-size: 16px;
  }

  .longterm-growth-eps-value {
    font-size: 18px;
  }

  .longterm-growth-metrics {
    padding: 24px;
    margin-top: 24px;
  }

  .longterm-growth-metrics-row {
    grid-template-columns: 1fr;
  }
  
  .longterm-growth-metric-title {
    font-size: 18px;
    margin-bottom: 16px;
  }
  
  .longterm-growth-metric-row {
    padding: 10px 0;
  }
} 