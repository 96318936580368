.longterm-overview {
  padding: 32px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.longterm-overview-header {
  margin-bottom: 40px;
  text-align: center;
}

.longterm-overview-header h1 {
  font-size: 32px;
  color: #1a1a1a;
  margin-bottom: 16px;
}

.longterm-overview-description {
  font-size: 16px;
  color: #666666;
  max-width: 600px;
  margin: 0 auto;
}

.longterm-overview-content {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

.longterm-overview-card {
  text-align: center;
  padding: 40px;
  background: #f8f9fa;
  border-radius: 12px;
  max-width: 400px;
}

.longterm-overview-icon {
  font-size: 64px;
  margin-bottom: 24px;
  animation: float 3s ease-in-out infinite;
}

.longterm-overview-card h2 {
  font-size: 24px;
  color: #1a73e8;
  margin-bottom: 16px;
}

.longterm-overview-card p {
  color: #666666;
  line-height: 1.6;
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
} 