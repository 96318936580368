.stock-rec-container {
  padding: 32px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.stock-rec-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 64px;
  margin-bottom: 48px;
  padding: 24px;
  background: #f8f9fa;
  border-radius: 16px;
}

.stock-rec-meter-container {
  flex: 0 0 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.stock-rec-meter {
  width: 380px;
  margin-bottom: 24px;
}

.stock-rec-total {
  font-size: 24px;
  font-weight: 500;
  color: #333;
  text-align: center;
  margin-top: 8px;
}

.stock-rec-ratings {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
  background: #fff;
  border-radius: 12px;
  min-width: 420px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.stock-rec-rating-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 8px 0;
}

.stock-rec-rating-label-wrapper {
  display: flex;
  align-items: center;
  min-width: 160px;
}

.stock-rec-rating-label {
  font-size: 15px;
  color: #1a73e8;
  font-weight: 500;
  white-space: nowrap;
}

.stock-rec-rating-container {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 16px;
  max-width: 280px;
}

.stock-rec-rating-track {
  flex: 1;
  height: 8px;
  background: #f0f0f0;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.stock-rec-rating-fill {
  height: 100%;
  border-radius: 5px;
  transition: width 0.3s ease;
  background: linear-gradient(90deg, #D32F2F, #FDD835 50%, #2E7D32);
}

.stock-rec-rating-arrow {
  position: absolute;
  top: -12px;
  transform: translateX(-50%);
}

.stock-rec-arrow-head {
  color: #1a73e8;
  font-size: 16px;
  line-height: 1;
}

.stock-rec-rating-value {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  text-align: right;
  min-width: 54px;
}

/* 技术指标部分 */
.stock-rec-indicators {
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid #eee;
}

.stock-rec-indicators-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

.stock-rec-indicator-card {
  background: #f8f9fa;
  padding: 24px;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.stock-rec-indicator-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  background: #fff;
}

.stock-rec-indicator-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.stock-rec-indicator-label {
  color: #1a73e8;
  font-size: 16px;
  font-weight: 500;
  cursor: help;
  display: inline-flex;
  align-items: center;
}

.stock-rec-indicator-value {
  color: #333;
  font-size: 24px;
  font-weight: 600;
  font-variant-numeric: tabular-nums;
}

.stock-rec-loading,
.stock-rec-error {
  padding: 48px;
  text-align: center;
  color: #666;
  font-size: 16px;
}

.stock-rec-error {
  color: #D32F2F;
  background-color: #FFEBEE;
  border-radius: 8px;
}

/* Ensure tooltip text is readable */
.tooltip-content {
  font-size: 13px;
  line-height: 1.5;
  max-width: 280px;
  padding: 8px 12px;
  background: rgba(0, 0, 0, 0.85);
  color: #fff;
  border-radius: 6px;
} 